<template>
  <q-page class="position-relative">
    <q-form class="q-mb-xl q-pa-md q-mx-auto q-my-auto q-gutter-md" @submit.prevent="login" style="max-width:30rem; width:100%">
        <q-input outlined type="text" label="username or email *" v-model="username" class=""
        lazy-rules:rules="[val => val !== null && val !== '' || 'Please type your username or email']" />
        <q-input outlined type="password" label="password *" v-model="password" class=""
        lazy-rules:rules="[val => val !== null && val !== '' || 'Password cannot be null']" />
        <!-- <q-input filled bg-color="primary" class="q-py-md q-mb-xl" input-class="text-white text-h5" type="submit"/> -->
        <div>
            <q-btn label="Login" class="q-py-md q-mb-xl full-width" color="primary" @click="login" />
        </div>
    </q-form>
  </q-page>
</template>

<script>
export default {
    name:'LoginPage',

    data(){
        return {
            username:'', password:''
        }
    },

    methods: {
        login(){
            this.$store.dispatch('auth/login', {username:this.username, password:this.password})
            .then(res => {
                this.$router.push('/')
            }).catch(error => {
                console.log(error)
            })
        }
    },

    mounted(){
        
    }
}
</script>

<style>

</style>