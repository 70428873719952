<template>
    <q-page padding>
      <div class="q-pa-md flex flex-center">
        <q-card class="q-pa-md" style="max-width: 600px; width: 100%;">
          <q-card-section>
            <div class="text-h6 text-center">Document Status</div>
          </q-card-section>
  
          <q-card-section class="q-mb-md break-all">
            <q-list class="fit">
              <q-item>
                <q-item-section>
                  <div>Signed Content Hash:</div>
                </q-item-section>
                <q-item-section>
                  <div class="text-bold">{{ status.signedContentHash }}</div>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <div>Signer Name:</div>
                </q-item-section>
                <q-item-section>
                  <div class="text-bold">{{ status.signerName }}</div>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <div>Signed Date & Time:</div>
                </q-item-section>
                <q-item-section>
                  <div class="text-bold">{{ status.signedDateTime }}</div>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <div>Certificate Serial Number:</div>
                </q-item-section>
                <q-item-section>
                  <div class="text-bold">{{ status.certificateSerialNumber }}</div>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <div>Document File Name:</div>
                </q-item-section>
                <q-item-section>
                  <div class="text-bold">{{ status.documentFileName }}</div>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <div>Document Status:</div>
                </q-item-section>
                <q-item-section>
                  <div class="text-bold">{{ status.documentStatus }}</div>
                </q-item-section>
              </q-item>
  
              <!-- Additional Info for Uploaded Document -->
              <template v-if="uploadedStatus">
                <q-item>
                  <q-item-section>
                    <div>Certificate Authority:</div>
                  </q-item-section>
                  <q-item-section>
                    <div class="text-bold">{{ uploadedStatus.certificateAuthority }}</div>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <div>Certificate Owner:</div>
                  </q-item-section>
                  <q-item-section>
                    <div class="text-bold">{{ uploadedStatus.certificateOwner }}</div>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <div>Is Signed:</div>
                  </q-item-section>
                  <q-item-section>
                    <q-icon :name="uploadedStatus.isSigned ? 'check_circle' : 'cancel'" :color="uploadedStatus.isSigned ? 'green' : 'red'" />
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <div>Valid Signature:</div>
                  </q-item-section>
                  <q-item-section>
                    <div class="text-bold">{{ uploadedStatus.validSignature }}</div>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <div>Content Integrity:</div>
                  </q-item-section>
                  <q-item-section>
                    <q-icon :name="uploadedStatus.contentIntegrity ? 'check_circle' : 'warning'" :color="uploadedStatus.contentIntegrity ? 'green' : 'red'" />
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
          </q-card-section>
  
          <q-card-section>
            <q-form @submit.prevent="uploadDocument">
              <q-file
                v-model="file"
                filled
                label="Upload PDF Document"
                accept="application/pdf"
                @change="handleFileChange"
              >
                <template v-slot:prepend>
                  <q-icon name="attach_file"/>
                </template>
              </q-file>
              <q-btn
                class="q-mt-md"
                label="Validate Signature"
                color="primary"
                type="submit"
                :disable="!file"
              ></q-btn>
            </q-form>
          </q-card-section>
  
          <q-card-section v-if="message" class="text-center">
            <q-banner dense color="green-4" text-color="white">
              {{ message }}
            </q-banner>
          </q-card-section>
        </q-card>
      </div>
    </q-page>
  </template>
  
  <script>
  import API from '@/services/api.service';
  export default {
    data() {
      return {
        status: {
          signedContentHash: "", 
          signerName: "", 
          signedDateTime: "", 
          certificateSerialNumber: "", 
          documentFileName: "", 
          documentStatus: "", 
        },
        uploadedStatus: null,
        file: null,
        message: "",
      };
    },
    methods: {
      handleFileChange(event) {
        this.file = event.target.files[0];
      },
      async uploadDocument() {
        if (!this.file) return;
        const formData = new FormData();
        formData.append("file", this.file);
  
        try {
          this.status = {}
          const response = await API.viewDocumentStatus(formData)
          this.status = response.data
          this.uploadedStatus = response.data
          this.message = "Signature validation completed!";
        } catch (error) {
          this.message = "Error validating signature. Please try again.";
        }
      },
    },
    async mounted() {
      const eventId = this.$route.query?.id
  
      if (eventId) {
        try {
          const formData = new FormData()
          formData.append('eventId', eventId)
          const response = await API.viewDocumentStatus(formData)
          const result = response.data
          this.status = {
            signedContentHash: result.signedContentHash || "N/A",
            signerName: result.signerName || "N/A",
            signedDateTime: result.signedDateTime || "N/A",
            certificateSerialNumber: result.certificateSerialNumber || "N/A",
            documentFileName: result.documentFileName || "N/A",
            documentStatus: result.documentStatus || "N/A",
          };
        } catch (error) {
          console.error("Failed to fetch document status.", error);
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .q-card {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .q-list {
    margin-bottom: 1rem;
  }
  .text-bold {
    font-weight: bold;
  }
  .q-item-section {
    align-items: center;
  }
  .break-all {
    word-wrap: break-word;
    word-break: break-all;
  }
  </style>
  