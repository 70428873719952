<template>
  <q-table 
    :columns="Columns" :rows="filter" 
    selection="multiple" v-model:selected="selected"
    :pagination="userPagination"
    >
    
    <template v-slot:top>

        <q-toolbar class="q-gutter-sm">
            <q-btn label="Add" icon="add" rounded color="primary" @click="showAddUser = true"/>
            <q-space/>
            <q-input v-model="search" placeholder="search users" outlined>
                <template v-slot:prepend><q-icon name='search'/></template>
            </q-input>
        </q-toolbar>
    </template>

    <template v-slot:top-row v-if="showAddUser">

        <q-tr>
            <q-td></q-td>
            <q-td>
                <q-input placeholder="name" v-model="newUser.firstName"/>
            </q-td>
            <q-td>
                <q-input placeholder="surname" v-model="newUser.lastName"/>
            </q-td>
            <q-td>
                <q-input placeholder="email" v-model="newUser.email" :rules="[userIsNew]"/>
            </q-td>
            <q-td>
                <q-input placeholder="username" v-model="newUser.username" :rules="[userIsNew]"/>
            </q-td>
            <!-- <q-td>
                <q-input placeholder="password" v-model="newUser.password"/>
            </q-td> -->
            <q-td>
                <q-select v-model="newUser.gender" :options="gender" emit-value map-options/>
            </q-td>
            <q-td>
                <q-select v-model="newUser.role" :options="roles" label="Role" outlined></q-select>
            </q-td>
            <!-- <q-td>
                <q-avatar color="accent" class="text-white">
                    <img :src="newUser.profile" v-if="newUser.profile.length > 0"/>
                    <q-icon name="person" v-else/>
                </q-avatar>
                <q-popup-edit v-model="newUser.profile" v-slot="scope" :cover="false" :offset="[0, 10]">
                    <q-input placeholder="profile url..." autofocus v-model="scope.value" @keyup.enter="scope.set"/>
                </q-popup-edit>
            </q-td> -->
        </q-tr>
        <q-tr>
            <q-td colspan="100%" align="right" class="q-gutter-sm">
                <q-btn label="Cancel" color="negative" @click="showAddUser = false"></q-btn>
                <q-btn label="Save" color="primary" @click="save(true)"></q-btn>
                <q-btn label="Save & Add" color="primary" @click="save(false)"></q-btn>
            </q-td>
        </q-tr>
    </template>

    <template v-slot:body="props">
        <q-tr :props="props">
            <q-td>
                <q-checkbox v-model="props.selected"></q-checkbox>
            </q-td>
            <q-td v-for="cell in editableCol" :key="cell.field">
                {{ props.row[cell.field] }}
                <q-popup-edit v-model="props.row[cell.field]" v-slot="scope">
                    <q-input :placeholder="`${props.row[cell.label]}`" v-model="scope.value" @keyup.enter="scope.set" autofocus/>
                </q-popup-edit>
            </q-td>
            <q-td auto-width>
                <q-select v-model="props.row.gender" :options="gender" emit-value map-options borderless dense/>
            </q-td>
            <q-td align="center" auto-width>
                <q-select v-model="props.row.role" :options="roles" borderless dense/>
            </q-td>
            <q-td align="right" auto-width>
                <q-avatar>
                    <img v-if="props.row.profile && props.row.profile !== ''" :src="props.row.profile"/>
                    <q-icon v-else name="person"/>
                </q-avatar>
                <q-popup-edit v-model="props.row.profile" v-slot="scope" :cover="false" :offset="[0, 10]">
                    <q-input placeholder="profile url..." autofocus v-model="scope.value" @keyup.enter="scope.set"/>
                </q-popup-edit>
            </q-td>
            <q-td align="right" auto-width>
                <q-btn icon="save" color="positive" dense flat @click="update(props.row)"/>
                <q-btn icon="delete" color="negative" dense flat @click="delUser(props.row)"/>
            </q-td>
        </q-tr>
    </template>
    
  </q-table>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return {
            Columns: [
                {label: 'Name', field:'firstName', align:'left'},
                {label: 'Surname', field:'lastName', align:'left'},
                {label: 'Email', field:'email', align:'left'},
                {label: 'Username', field:'username', align:'left'},
                {label: 'Gender', field:'gender'},
                {label: 'Role', field:'role', align:'center'},
                {label: 'Profile', field:'profile'}
            ],
            userPagination: {
                rowsPerPage: 10
            },
            roles:['admin', 'manager','psychologist','client'], 
            gender:[{label:'male', value:'m'}, {label:'female', value:'f'}],
            search:'', selected:[],
            newUser:{
                firstName:'', lastName:'', username:'', password:'', profile:'', role:'client'
            }, showAddUser:false,
        }
    },
    computed:{
        ...mapState({
            user: state => state.auth.user,
            users: state => state.db.users
        }),
        filterRole(role){return this.users.filter(item => item.role === role)},
        filter(){
            let search = this.search
            return this.users.filter(item => {
                return item.firstName.toLowerCase().includes(search.toLowerCase()) ||
                        item.lastName.toLowerCase().includes(search.toLowerCase()) ||
                        item.username.toLowerCase().includes(search.toLowerCase()) ||
                        item.role.toLowerCase().includes(search.toLowerCase())
            })
        },

        editableCol(){
            return this.Columns.filter(item => !['gender', 'role', 'profile'].includes(item.field))
        }
    },
    methods:{
        initials(name, surname){return `${name.toUpperCase().charAt(0)}${surname.toUpperCase().charAt(0)}`},
        userIsNew(username){
            let exist = this.users.find(item => item.username === username)
            if(exist)return 'This username is taken'
            return true
        },
        save(dismiss){
            this.$store.dispatch('db/createUser', this.newUser)
            .then(() => {
                this.newUser = {
                    firstName:'', lastName:'', username:'', password:'', profile:'', role:'user'
                }
            })
            if(dismiss)this.showAddUser = false
        },
        update(user){
            this.$store.dispatch('db/updateUser', {
                id:user._id, data:user
            })
        },
        delUser(user){
            console.log(user)
            this.$store.dispatch('db/deleteUser', user)
        }
    },
    mounted(){
        if(this.users.length === 0)this.$store.dispatch('db/getUser','')
    }
}
</script>

<style>

</style>