<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar v-if="!minimalist">
        <!-- <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        /> -->

        <q-toolbar-title>
          Psikometri Online
        </q-toolbar-title>

        <!-- <q-btn label="User" to="/user" stretch flat/>
        <q-btn label="Lab" to="/survey" stretch flat/>
        <q-btn label="Test" to="/submission" stretch flat/>
        <q-btn label="Report" to="/testview" stretch flat/> -->

        <q-tabs v-if="user" v-model="mainMenuTab" inline-label>
          <q-route-tab label="Patients" to="/user"/>
          <q-route-tab v-if="user.role == 'admin'" label="Lab" to="/survey"/>
          <q-route-tab label="Tests" to="/submission"/>
          <q-route-tab label="Report" to="/testview"/>
        </q-tabs>

        <q-btn-dropdown color="accent" v-if="user">
          <template v-slot:label>
            <q-avatar size="lg" flat>
              <img v-if="user.profile" :src="user.profile"/>
              <div else-if>{{ initials }}</div>
            </q-avatar>
          </template>
          <q-list>
            <div v-for="item in accountMenu" :key="item.label">
              <q-item  clickable v-close-popup @click="item.click">
                <q-item-section avatar>
                  <q-avatar :icon="item.icon"/>
                </q-item-section>
                <q-item-section>{{ item.label }}</q-item-section>
                <q-separator v-if="item.separator" />
              </q-item>
              <q-separator v-if="item.separator"/>
            </div>
          </q-list>
        </q-btn-dropdown>
        <!-- <q-btn v-else label="Login" to="/login" color="accent"/> -->
      </q-toolbar>
    </q-header>

    <!-- <q-drawer
      v-if="!minimalist"
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      :width="200"
      class="bg-grey-2"
    >
      <q-list separator>
        <q-item to="/user">User</q-item>
        <q-item to="/survey">Survey</q-item>
        <q-item to="/submission">Submission</q-item>
        <q-item to="/testview">Test View</q-item>
      </q-list>
    </q-drawer> -->

    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import { Notify } from 'quasar';
import API from '@/services/api.service'

export default {
  name: 'LayoutDefault',

  data(){
    return {
      leftDrawerOpen: false, always:true,
      mainMenuTab: "",
      // Menus
      accountMenu: [
        {label: 'Settings', icon:'settings', separator:false},
        {label: 'Logout', icon:'logout', separator:false, click: ()=>{
          this.$store.commit('auth/logout')
          this.$router.push('/login')
        }},
      ]
    }
  },

  components: {
    
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authorized: state => state.auth.authorized,
    }),
    ...mapGetters({
      initials: 'auth/initials'
    }),
    minimalist(){
      return this.$route.path.includes('/submit') || this.$route.path.includes('/verify')
    },
    token(){return localStorage.getItem('x-access-token')},
    routeTab(){
      console.log(this.$route.path)
      return 'user'
    }
    
  },

  mounted(){
    console.log(this.$route.path, this.$route.path.includes('/verify'))
    if(this.$route.path.includes('/verify'))return
    this.mainMenuTab = this.$route.path
    const token = this.$route.query.token || localStorage.getItem('x-access-token')
    if(!API.isTokenExpired(token))return
    API.refresh()
    .catch(err => {
      if(this.user)Notify.create(`Your session has expired! Please login again`)
      this.$route.push('/login')
    })
  }
  
}
</script>
