import API from '../services/api.service'
import Notify from 'quasar/src/plugins/Notify.js';

const db = {
    namespaced: true,

    state: {
        users: [],
        tests: [],
        submissions: [],
    },

    actions: {

        // User Actions
        createUser({ commit }, data){
            return API.createUser(data)
            .then(res => {
                commit('setUser', res.data)
                Notify.create({message: `New user ${res.data.username} created!`})
                return Promise.resolve(data)
            })
            .catch(err => {return Promise.reject(err)})
        },
        getUser({ commit }, query){
            API.getUser(query).then(res => {
                commit('setUsers', res.data)
            }).catch(err => {
                console.error(err.response.data)
                Notify.create({
                    message: err.response.data,
                })
            })
        },
        updateUser({ commit }, payload){
            API.updateUser(payload).then(res => {
                commit('setUser', res.data)
                Notify.create({message:`User ${payload.data.username} updated!`})
            }).catch(err => {
                Notify.create({
                    message: err.response.data
                })
            })
        },
        deleteUser({ commit }, user){
            API.deleteUser(user._id)
            .then(res => {
                commit('deleteUser', res.data)
                Notify.create({message:`User ${user.username} deleted!`})
            })
            .catch(err => {Notify.create({message:err.response.data})})
        },

        // Test Actions
        createTest({ commit }, data){
            return API.createTest(data).then(res => {
                commit('setTest', data)
                Notify.create({message: `New test ${res.data.title} created!`})
                return Promise.resolve(data)
            }).catch(err => {return Promise.reject(err)})
        },
        getTest({ commit }, query){
            API.getTest(query).then(res => {
                commit('setTests', res.data)
            }).catch(err => {
                console.error(err.response.data)
                Notify.create({
                    message: err.response.data,
                })
            })
        },
        updateTest({ commit }, payload){
            API.updateTest(payload).then(res => {
                commit('setTest', res.data)
                Notify.create({message:`Test ${payload.data.title} updated!`})
            }).catch(err => {
                Notify.create({
                    message: err.response.data
                })
            })
        },
        deleteTest({ commit }, test){
            API.deleteTest(test._id).then(res => {
                commit('deleteTest', res.data)
                Notify.create({message:`Test ${test.title} deleted!`})
            }).catch(err => {Notify.create({message:err.response.data})})
        },

        // Submission Actions
        createSubmission({ commit }, data){
            // console.log(this.state)
            return API.createSubmission(data).then(res => {
                let client = this.state.db.users.find(item => item._id === res.data.client)
                let psychologist = this.state.db.users.find(item => item._id === res.data.psychologist)
                let test = this.state.db.tests.find(item => item._id === res.data.test)
                res.data.psychologist = {...psychologist}
                res.data.client = {...client}
                res.data.test = {...test}
                console.log(res.data)
                commit('setSubmission', res.data)
                Notify.create({message: `New submission created!`})
                return Promise.resolve(res.data)
            }).catch(err => {return Promise.reject(err)})
        },
        getSubmission({ commit }, query){
            API.getSubmission(query).then(res => {
                commit('setSubmissions', res.data)
            }).catch(err => {
                console.error(err.response.data)
                Notify.create({
                    message: err.response.data,
                })
            })
        },
        updateSubmission({ commit }, payload){
            API.updateSubmission(payload).then(res => {
                console.log('updateSubmission', res, payload)
                commit('setSubmission', res.data)
                Notify.create({message:`Submission by ${payload.client.username} updated!`})
            }).catch(err => {
                Notify.create({
                    message: err.response.data
                })
            })
        },
        deleteSubmission({ commit }, submission){
            API.deleteSubmission(submission._id).then(res => {
                console.log(res.data)
                commit('deleteSubmission', res.data)
                Notify.create({message:`Submission by ${submission.client.username} deleted!`})
            }).catch(err => {
                console.log(err)
                Notify.create({message:err.response.data})
            })
        },
    },

    mutations: {
        // User Commits
        setUser(state, user){
            let match = state.users.find(item => item._id === user._id)
            if(!match)state.users.push(user)
            else match = user
        },
        setUsers(state, users){state.users = users},
        deleteUser(state, user){state.users = state.users.filter(item => item._id !== user._id)},

        // Test Commits
        setTest(state, test){
            let match = state.tests.find(item => item._id === test._id)
            if(!match)state.tests.push(test)
            else match = test
        },
        setTests(state, tests){state.tests = tests},
        deleteTest(state, test){state.tests = state.tests.filter(item => item._id === test._id)},

        // Submission Commits
        setSubmission(state, submission){
            let match = state.submissions.find(item => item._id === submission._id)
            if(!match)state.submissions.push(submission)
            else match = submission
        },
        setSubmissions(state, submissions){state.submissions = submissions},
        deleteSubmission(state, submission){state.submissions = state.submissions.filter(item => item._id !== submission.id)},
    },

    getters: {
        // User Getters
        psychologists: state => {
            return state.users.map(user => {
                if(user.role !== 'client')return user
            }) || []
        },
    }
}

export default db