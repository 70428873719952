// VARIABLES FOR Validity Scales
var validity = ['L','F', 'Fb', 'F(p)', 'K','VRIN','TRIN', 'Ds'];
var fpScale = ['F(p)','F(p1)','F(p2)'];
var validityOther = ['Ds','F(p)','Mp','Sd','S','Ss'];
var superlativeScale = ['S','S1','S2','S3','S4','S5'];
// VARIABLES FOR Clinical Scales
var clinical = ['Hs','D','Hy','Pd','Mf-m', 'Mf-f', 'Pa','Pt','Sc','Ma','Si'];
var hypochondriasis = ['Hs', 'Hs-K'];
var depression = ['D','D-O','D-S','D1','D2','D3','D4','D5'];
var hysteria = ['Hy','Hy-O','Hy-S','Hy1','Hy2','Hy3','Hy4','Hy5'];
var psychopathic = ['Pd','Pd-O','Pd-S','Pd1','Pd2','Pd3','Pd4','Pd5'];
var mF_M = ['Mf-m','Mf1','Mf2','Mf3','Mf4','Mf5','Mf6','Mf7','F-M-m',];
var paranoia = ['Pa','Pa-O','Pa-S','Pa1','Pa2','Pa3'];
var psychasthenia = ['Pt', 'Pt-K'];
var schizo = ['Sc','Sc-K','Sc1','Sc2','Sc3','Sc4','Sc5','Sc6'];
var hypomania = ['Ma','Ma-K','Ma-O','Ma-S','Ma1','Ma2','Ma3','Ma4'];
var introversion = ['Si','Si1','Si2','Si3'];

// Score function
const calcTScore = (score, mean, sd) => {
    let t = (score - mean) * 10 / sd + 50
    return parseInt(t.toFixed(0)) || null
}

const allValidityScales = [
    ...validity,
    ...fpScale,
    ...validityOther,
    ...superlativeScale
]

const allClinicalScales = [
    ...hypochondriasis,
    ...depression,
    ...hysteria,
    ...psychopathic,
    ...mF_M,
    ...paranoia,
    ...psychasthenia,
    ...schizo,
    ...hypomania,
    ...introversion
]



const getScaleResults = (scales, answers, gender, filter) => {
    filter = filter.map(item => {
        if(item == 'Mf-m' && gender == 'f')item = 'Mf-f'
        else if(item == 'Mf-f' && gender == 'm')item = 'Mf-m'
        else if(item == 'Mf6-m' && gender == 'f')item = 'Mf6-f'
        else if(item == 'Mf6-f' && gender == 'm')item = 'Mf6-m'
        else if(item == 'F-M-m' && gender == 'f')item = 'F-M-f'
        else if(item == 'F-M-f' && gender == 'm')item = 'F-M-m'

        return item
    })
    let filteredScales = scales.filter(item => filter.includes(item.abrv))
    return filteredScales.map(item => {

        let trueScores = answers.filter(ans => {
            return item.answers.trueList.includes(ans.index) &&
                    ans.score === 1
        }).length

        let falseScores = answers.filter(ans => {
            return item.answers.falseList.includes(ans.index) &&
                    ans.score === 0
        }).length

        let pairScores = item.answers.pairs ? item.answers.pairs.reduce((total, pair) => {
            let questions = pair.split('-')
            let Q1 = {
                index: parseInt(questions[0].substring(0, questions[0].length - 1)),
                score: questions[0].includes('T') ? 1 : 0
            }
            let Q2 = {
                index: parseInt(questions[1].substring(0, questions[1].length - 1)),
                score: questions[1].includes('T') ? 1 : 0
            }
            let score = answers.find(ans => ans.index == Q1.index && ans.score == Q1.score) &&
                        answers.find(ans => ans.index == Q2.index && ans.score == Q2.score) ? 1: 0
            // console.log('scale', item.abrv, 'pair',pair, 'Q', questions, 'Q1', Q1, 'Q2', Q2, 'score', score)
            return total + score
        },0) : 0

        let subtractPairScores = item.answers.subtractPairs ? item.answers.subtractPairs.reduce((total, pair) => {
            let questions = pair.split('-')
            let Q1 = {
                index: parseInt(questions[0].substring(0, questions[0].length - 1)),
                score: questions[0].includes('T') ? 1 : 0
            }
            let Q2 = {
                index: parseInt(questions[1].substring(0, questions[1].length - 1)),
                score: questions[1].includes('T') ? 1 : 0
            }
            let score = answers.find(ans => ans.index == Q1.index && ans.score == Q1.score) &&
                        answers.find(ans => ans.index == Q2.index && ans.score == Q2.score) ? -1: 0
            // console.log('scale', item.abrv, 'pair',pair, 'Q', questions, 'Q1', Q1, 'Q2', Q2, 'score', score)
            return total + score
        },0) : 0

        let mean = gender === 'm' ? item.statistics.mean.m : item.statistics.mean.f
        let sd = gender === 'm' ? item.statistics.sd.m : item.statistics.sd.f
        let tScore = calcTScore(trueScores + falseScores + pairScores + subtractPairScores, mean, sd)
        return {
            title: item.title,
            abrv: item.abrv,
            tScore,
            args: {mean, sd, trueScores, falseScores, statistics: item.statistics, gender}
        }
    })
}

const chartBounderies = (data) => {
    return [
        {
            data:data.map(item => 0),
            backgroundColor:'rgba(230,10,10,0.1)',
            fill:'2'
        },
        {
            data:data.map(item => 30),
            backgroundColor:'rgba(230,180,10,0.1)',
            fill:'3'
        },
        {
            data:data.map(item => 40),
            backgroundColor:'rgba(10,230,10,0.1)',
            fill:'4'
        },
        {
            data:data.map(item => 60),
            backgroundColor:'rgba(230,110,10,0.1)',
            fill:'5'
        },
        {
            data:data.map(item => 70),
            backgroundColor:'rgba(230,10,10,0.1)',
            fill:'6'
        },
        {
            data:data.map(item => 100),
            backgroundColor:'rgba(230,110,10,0.1)',
            fill:'7'
        },
    ]
}

export default {
    getScaleResults,
    filters: {
        validity, clinical,
        allValidityScales, allClinicalScales
    },
    chartBounderies,
}