<template>
    <q-linear-progress v-if="fetchProgress" indeterminate color="accent" class="" />
    <q-banner class="text-white bg-red" v-if="fetchError">
        {{ fetchError }}
        <template v-slot:action>
            <q-btn label="Dismiss" flat @click="fetchError = null" />
            <q-btn label="Try Again" flat @click="fetchSubmission(urlParams)" />
        </template>
    </q-banner>
    <q-banner v-else class="text-white bg-secondary q-mt-md" dense>
        <template v-slot:avatar>
            <q-avatar size="10rem">
                <img :src="submission.client.profile || 'https://cdn.quasar.dev/img/boy-avatar.png'">
            </q-avatar>
        </template>
        <div>
            <h4>{{ submission.client.firstName }} {{ submission.client.lastName }}</h4>
            <i>Interpret by {{ submission.psychologist.firstName }} {{ submission.psychologist.lastName }}</i><br>
            Test: {{ submission.test.title }} ({{ submission.test.abrv }})
            <p>Submitted On: {{ formatDate(submission.updatedAt) }}</p>
            Answered: {{ submission?.answers?.length }} / {{ submission?.test?.questions?.list?.length }}
        </div>
    </q-banner>

    <div class="row q-gutter-md q-mt-xl q-pl-lg">
        <!-- <LineChart v-if="subScales.length > 0" :chartData="subScalesChart" :options="lineOpt"/>
        <LineChart :chartData="validityChart" :options="lineOpt"/>
        <RadarChart :chartData="clinicalChart" :options="radarOpt"/> -->

        <!-- Validity Scale -->
        <apexchart :options="validityChart.options" :series="validityChart.series"></apexchart>

        <!-- Validity Scale -->
        <apexchart :options="clinicalChart.options" :series="clinicalChart.series"></apexchart>

        <!-- Validity Scale -->
        <apexchart :options="clinicalRadarChart.options" :series="clinicalRadarChart.series"></apexchart>
    </div>

    <!-- <div class="text-h2 q-mx-auto text-center q-my-md q-mb-lg">{{ submission.test.title }}</div> -->
    <div class="q-mx-md q-px-sm">
        <h3>Score Tables</h3>
        <!-- Tables -->
        <div class="">
            <div class="row q-gutter-sm" style="max-height:50rem; height: fit-content;">
                <!-- Client Validity Summary -->
                <q-table :columns="validitySummaryCol" :rows="validitySummaryRows" row-key="abrv"
                    v-model:expanded="scaleExpanded" virtual-scroll v-model:pagination="summaryPagination"
                    :rows-per-page-options="[0]" :virtual-scroll-sticky-size-start="48" class="summaryTable col">
                    <template v-slot:body="props">
                        <q-tr :props="props" :class="props.expand ? 'bg-accent text-white' : ''"
                            @click="toggleExpanded(props.row.abrv)">
                            <q-td key="title">{{ props.row.title }}</q-td>
                            <q-td key="abrv" auto-width>{{ props.row.abrv }}</q-td>
                            <q-td key="tScore" align="right" auto-width>{{ props.row.tScore }}</q-td>
                        </q-tr>
                        <q-tr v-if="props.expand && subScales.length > 0" class="bg-secondary text-white">
                            <q-td colspan="100%">
                                <q-list separator>
                                    <q-item class="row q-gutter-sm" v-for="item in subScales" :key="item.abrv">
                                        <div class="col">{{ item.title }}</div>
                                        <div class="q-mr-lg">{{ item.abrv }}</div>
                                        <div class="text-right">{{ item.tScore }}</div>
                                    </q-item>
                                </q-list>
                            </q-td>

                        </q-tr>

                    </template>
                </q-table>
                <!-- Client Clinical Summary -->
                <q-table :columns="validitySummaryCol" :rows="clinicalSummaryRows" row-key="abrv"
                    v-model:expanded="scaleExpanded" virtual-scroll v-model:pagination="summaryPagination"
                    :rows-per-page-options="[0]" wrap-cells :virtual-scroll-sticky-size-start="48"
                    class="summaryTable col">
                    <template v-slot:body="props">
                        <q-tr :props="props" :class="props.expand && 'bg-accent text-white'"
                            @click="toggleExpanded(props.row.abrv)">
                            <q-td key="title">{{ props.row.title }}</q-td>
                            <q-td key="abrv" auto-width>{{ props.row.abrv }}</q-td>
                            <q-td key="tScore" align="right" auto-width>{{ props.row.tScore }}</q-td>
                        </q-tr>

                        <q-tr v-if="props.expand" class="bg-secondary text-white">
                            <q-td colspan="100%">
                                <q-list separator>
                                    <q-item class="row q-gutter-sm" v-for="item in subScales" :key="item.abrv">
                                        <div class="col">{{ item.title }}</div>
                                        <div class="q-mr-lg">{{ item.abrv }}</div>
                                        <div class="text-right">{{ item.tScore }}</div>
                                    </q-item>
                                </q-list>
                            </q-td>

                        </q-tr>
                    </template>
                </q-table>
            </div>
            <!-- Client Answers Details -->
            <h5 v-if="selectedScaleAnswers">Client Answers for selected scale</h5>
            <div class="row q-mt-lg q-gutter-sm" v-if="selectedScaleAnswers">
                <q-table class="col" :rows="selectedScaleAnswers.trueList" title="True List" wrap-cells>
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td key="index" auto-width align="left">{{ props.row.index }}</q-td>
                            <q-td key="question">{{ props.row.question }}</q-td>
                            <q-td key="score" auto-width align="center">{{ props.row.score }}</q-td>
                        </q-tr>
                    </template>
                </q-table>
                <q-table class="col" :rows="selectedScaleAnswers.falseList" title="False List" wrap-cells>
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td key="index" auto-width align="left">{{ props.row.index }}</q-td>
                            <q-td key="question">{{ props.row.question }}</q-td>
                            <q-td key="score" auto-width align="center">{{ props.row.score }}</q-td>
                        </q-tr>
                    </template>
                </q-table>
            </div>
        </div>

    </div>

    <div class="q-mx-md q-px-sm" style="max-width: 50rem; margin-inline: auto;">
        <div v-if="submission.openAI?.message">
            <h4>AI interpretation</h4>
            <p>
                We used ChatGPT to generate a brief interpration of the test results.
                Please consider that the interpretation is based on common knowledge from MMPI-2
                and under no circumstances it's not to be used without specialist's consultation.
            </p>
            <h5>Test Validity</h5>
            <p>{{ submission.openAI?.message?.validity }}</p>
            <h5>Diagnosis</h5>
            <p>{{ submission.openAI?.message?.diagnosis }}</p>
            <h5>Treatment</h5>
            <p>{{ submission.openAI?.message?.treatment }}</p>
            <h5>Conclusion</h5>
            <p>{{ submission.openAI?.message?.conclusion }}</p>
        </div>
        <q-btn 
            v-else label="Generate AI Interpration" icon-right="settings" 
            @click="interpretAI"
            color="secondary"
            class="q-mx-auto q-my-lg"
            :loading="fetchProgress"
        />
    </div>

</template>

<script>
import { mapState } from 'vuex'
import { date } from 'quasar'
import API from '@/services/api.service'
import mmpi from '@/utilities/mmpi'
import VueApexCharts from 'vue3-apexcharts'
// import { DoughnutChart, LineChart, RadarChart } from 'vue-chart-3'
// import { Chart, registerables } from 'chart.js'

// Chart.register(...registerables)



export default {
    name: 'TestViewPage',

    // components: { LineChart, RadarChart },
    components: {
        apexchart: VueApexCharts
    },

    data() {
        return {
            submission: { client: { profile: '' }, psychologist: {}, test: {} },
            fetchProgress: false, fetchError: null,
            summaryPagination: { rowsPerPage: 0 },
            radarOpt: {
                scales: {
                    r: {
                        ticks: {
                            beginAtZero: true, color: 'red'
                        }, min: 0,
                    }
                }
            },
            lineOpt: {
                scales: {
                    y: {
                        ticks: { steps: 10 },
                        min: 0,
                        grid: {
                            color: function (context) {
                                if (context.tick.value > 70 || context.tick.value < 30) return 'red'
                                else if (context.tick.value > 60 || context.tick.value < 40) return 'orange'
                                else return 'green'
                            }
                        },
                        stacked: false
                    }
                },
                plugins: {
                    legend: { display: false }
                }
            },
            scaleExpanded: [], subScales: [],
        }
    },

    computed: {
        ...mapState({
            submissions: state => state.db.submissions,
            tests: state => state.db.tests,
        }),
        urlParams() {
            let url = "?"
            let query = this.$route.query
            for (const [key, val] of Object.entries(query)) {
                if(key == 'token')continue
                url += key + "=" + val + "&"
            }
            return url
        },

        validityChart() {
            return {
                series: [
                    {
                        name: "T-score",
                        data: this.validitySummaryRows.map(item => { return item.tScore }),
                    }
                ],
                options: {
                    chart: {
                        width: 420,
                        height: 350,
                        type: 'bar'
                    },
                    title: {
                        text: 'Validity Scale', align: 'left'
                    },
                    xaxis: {
                        categories: this.validitySummaryRows.map(item => { return item.abrv })
                    },
                    annotations: {
                        yaxis: [
                            {
                                y: 0,
                                y2: 40,
                                borderColor: 'transparent',
                                fillColor: 'rgba(173, 216, 230, 0.5)'
                            },
                            {
                                y: 40,
                                y2: 60,
                                borderColor: 'transparent',
                                fillColor: 'rgba(144, 238, 144, 0.5)'
                            },
                            {
                                y: 60,
                                y2: 65,
                                borderColor: 'transparent',
                                fillColor: 'rgba(255, 255, 153, 0.5)'
                            },
                            {
                                y: 65,
                                y2: 80,
                                borderColor: 'transparent',
                                fillColor: 'rgba(255, 165, 0, 0.5)'
                            },
                            {
                                y: 80,
                                y2: this.maxValidityScore,
                                borderColor: 'transparent',
                                fillColor: 'rgba(255, 69, 0, 0.5)'
                            }
                        ]
                    }
                }
            }
        },

        subScalesChart() {
            return {
                labels: this.subScales.map(item => item.abrv),
                datasets: [
                    {
                        label: `subscales of ${this.scaleExpanded[0]}`,
                        data: this.subScales.map(item => item.tScore),
                        backgroundColor: 'red'
                    },
                    ...mmpi.chartBounderies(this.subScales)
                ]
            }
        },

        clinicalChart() {
            return {
                series: [
                    {
                        name: "T-score",
                        data: this.clinicalSummaryRows.map(item => { return item.tScore })
                    }
                ],
                options: {
                    chart: {
                        width: 420,
                        height: 350,
                        type: 'bar'
                    },
                    title: {
                        text: 'Clinical Scale', align: 'left'
                    },
                    xaxis: {
                        categories: this.clinicalSummaryRows.map(item => { return item.abrv })
                    },
                    annotations: {
                        yaxis: [
                            {
                                y: 0,
                                y2: 40,
                                borderColor: 'transparent',
                                fillColor: 'rgba(173, 216, 230, 0.5)'
                            },
                            {
                                y: 40,
                                y2: 60,
                                borderColor: 'transparent',
                                fillColor: 'rgba(144, 238, 144, 0.5)'
                            },
                            {
                                y: 60,
                                y2: 65,
                                borderColor: 'transparent',
                                fillColor: 'rgba(255, 255, 153, 0.5)'
                            },
                            {
                                y: 65,
                                y2: 80,
                                borderColor: 'transparent',
                                fillColor: 'rgba(255, 165, 0, 0.5)'
                            },
                            {
                                y: 80,
                                y2: this.maxClinicalScore,
                                borderColor: 'transparent',
                                fillColor: 'rgba(255, 69, 0, 0.5)'
                            }
                        ]
                    }
                }
            }
        },

        clinicalRadarChart() {
            return {
                labels: this.clinicalSummaryRows.map(item => { return item.abrv }),
                datasets: [
                    {
                        data: this.clinicalSummaryRows.map(item => { return item.tScore }),
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgb(255, 99, 132)',
                    }
                ],
                series: [
                    {
                        name: "Max Values",
                        data: this.clinicalSummaryRows.map(item => { return this.maxClinicalScore }),
                        // showInLegend: false
                    },
                    {
                        name: "Clinical Attention",
                        data: this.clinicalSummaryRows.map(item => { return 65 }),
                        // showInLegend: false
                    },

                    {
                        name: "T-score",
                        data: this.clinicalSummaryRows.map(item => { return item.tScore })
                    },

                ],
                options: {
                    chart: {
                        height: 350,
                        width: 350,
                        type: 'radar'
                    },
                    xaxis: {
                        categories: this.clinicalSummaryRows.map(item => { return item.abrv })
                    },
                    yaxis: {
                        stepSize: 20
                    },
                    fill: {
                        opacity: [0.1, 0.2, 0.1],
                        colors: ['#FF0000', '#00FFFF', '#008FFB']
                    },
                    // stroke: {
                    //     width: [2, 0, 0]
                    // },
                    markers: {
                        size: [0, 0, 4]
                    }
                }
            }
        },

        maxClinicalScore() {
            return this.clinicalSummaryRows.reduce((result, item) => {
                return Math.max(100, Math.max(result, item.tScore))
            }, 0)
        },

        maxValidityScore() {
            return this.validitySummaryRows.reduce((result, item) => {
                return Math.max(100, Math.max(result, item.tScore))
            }, 0)
        },

        validitySummaryCol() {
            return [
                { label: 'Scale', field: 'title', align: 'left' },
                { label: 'Abrv', field: 'abrv', align: 'left' },
                { label: 'T-score', field: 'tScore', align: 'right' },
            ]
        },
        validitySummaryRows() {
            if (!this.submission.test._id) return []
            let test = this.submission.test
            if (!test) return []
            return mmpi.getScaleResults(test.scale, this.submission.answers, this.submission.client.gender, mmpi.filters.validity)
            // return mmpi.getScaleResults(test.scale, this.submission.answers, this.submission.client.gender, mmpi.filters.allValidityScales)
        },
        clinicalSummaryRows() {
            if (!this.submission.test._id) return []
            let test = this.submission.test
            if (!test) return []
            return mmpi.getScaleResults(test.scale, this.submission.answers, this.submission.client.gender, mmpi.filters.clinical)
        },
        selectedScaleAnswers() {
            if (!this.scaleExpanded[0]) return false
            return this.getAnswers(this.scaleExpanded[0])
        },
        test() {
            return this.tests.find(item => item._id === this.submission.test._id)
        }

    },

    methods: {
        fetchSubmission(query) {
            this.fetchProgress = true
            this.fetchError = null
            API.getSubmission(query, this.$route.query?.token)
            .then(res => {
                // console.log(res.data)
                this.submission = res.data[0] || null
                this.fetchProgress = false
            })
            .catch(err => {
                this.$q.notify({
                    message: err.response.data?.message
                })
                // this.fetchError = err.response.data?.message
                this.fetchProgress = false
                console.error(err.response.data)
            })
        },

        interpretAI() {
            this.fetchProgress = true
            this.fetchError = null
            API.interpretTestAI(this.submission._id)
            .then(res => {
                this.submission.openAI = res.data
            })
            .catch(err => {
                this.$q.notify({ message: err.response.data })
                this.fetchError = err.response.data
            })
            .finally(() => { this.fetchProgress = false })
        },

        getAnswers(abrv) {
            let scale = this.submission.test.scale.find(item => item.abrv === abrv)
            if (!scale) return []
            let questions = this.submission.test.questions.list
            let answers = this.submission.answers.map(item => {
                let question = questions.find(quest => quest.index === item.index)
                question = question.sq || question.default || ''
                let score = item.score === 1 ? 'Yes' : 'No'
                return {
                    index: item.index,
                    question,
                    score
                }
            })
            let trueList = answers.filter(ans => scale.answers.trueList.includes(ans.index))
            let falseList = answers.filter(ans => scale.answers.falseList.includes(ans.index))
            return {
                trueList, falseList
            }
        },
        toggleExpanded(abrv) {
            if (this.scaleExpanded[0] === abrv) {
                this.scaleExpanded = []
                this.subScales = []
            }
            else {
                this.scaleExpanded = [abrv]
                this.subScales = this.getSubScales(abrv)
            }
        },
        getSubScales(abrv) {
            // console.log('getSubscales', abrv)
            // let scale = this.submission.test.scale.find(item => item.abrv === abrv)
            // if(!scale.subs)return []
            // if(scale.type !== 'master' && !scale.subs)return[]
            // console.log(scale)
            // return mmpi.getScaleResults(this.submission.test.scale, this.submission.answers, this.submission.client.gender, scale.subs)

            let subs = this.submission.test.scale.filter(item => item.master == abrv)
            subs = subs.map(item => item.abrv)
            return mmpi.getScaleResults(this.submission.test.scale, this.submission.answers, this.submission.client.gender, subs)
        },

        formatDate(dt) { return date.formatDate(dt, 'DD/MM/YYYY') }
    },

    mounted() {
        this.fetchSubmission(this.urlParams)
    }
}
</script>

<style scoped>
.applicant-card {
    max-width: 20rem;
}

.summaryTable {
    height: inherit
}
</style>