import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import store from "./store";
import Notify from 'quasar/src/plugins/Notify.js';;
import Home from '@/pages/Home'
import User from '@/pages/User'
import Survey from '@/pages/Survey'
import Submission from '@/pages/Submission'
import TestView from '@/pages/TestView'
import TestSubmit from '@/pages/TestSubmit'
import Login from '@/pages/Login'
import VerifyDocument from "./pages/VerifyDocument.vue";

const routes = [
    {path: '/', component: Home},
    {path: '/login', component: Login},
    {path: '/user', component: User},
    { path: '/survey', component: Survey },
    { path: '/submission', component: Submission },
    {path: '/testview', component: TestView},
    {path: '/submit', component: TestSubmit},
    { path: '/verify/document', component: VerifyDocument }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    console.log('router',from.path,to.path)
    if(publicRoutes.includes(to.path) || to.query?.token)next()
    else {
        let user = store.state.auth.user
        let token = localStorage.getItem('x-access-token')
        if(!user || user === null || !token){
            Notify.create('You are not authorized to view this page. Please login!')
            next('/login')
        }
        else next()
    }
    
})

const publicRoutes = [
    '/submit', '/login', '/verify/document'
]

export default router